export type CampaignType = "rn" | "caregiver";

export function getCampaignRedirectUrl(campaignType: CampaignType) {
  switch (campaignType) {
    case "caregiver":
      return "/";

    case "rn":
      return "/onboarding";
  }
}

import { useToken } from "@shared/hooks/HttpApi/useToken";
import { getCampaignRedirectUrl } from "@shared/utils/campaignUtils";
import { Navigate, useParams, useSearchParams } from "react-router-dom";
import OnboardingCampaignView from "./CampaignView";

const CampaignRoute = () => {
  const params = useParams<{ campaign: string }>();
  const [searchParams] = useSearchParams();
  const { token } = useToken();
  const campaignType = searchParams.get("type") === "rn" ? "rn" : "caregiver";
  const redirectTo = getCampaignRedirectUrl(campaignType);

  if (params.campaign === undefined || token !== null) {
    console.log("redirecting to home", { ...params, token, campaignType });
    return <Navigate to={redirectTo} />;
  }

  return <OnboardingCampaignView campaign={params.campaign} redirectTo={redirectTo} />;
};

export default CampaignRoute;
